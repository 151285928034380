.custom-card-root-container {
    height: 100%;
    min-height: 500px;
}

.getstarted-card {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    height: 100%;
    font-size: 1.5em;
    background-color: rgba(255, 255, 255, 0.9);
    color: rgba(255, 255, 255, 0.9);
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.getstarted-card-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
}

.getstarted-card-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    padding-top: 5px;
    text-align: left;
    max-width: 90%;
    white-space: wrap;
    text-wrap: pretty;
    color: rgba(255, 255, 255, 0.9);
}

.getstarted-card-icon {
    height: 60px;
    font-size: 1.5em;
}

.getstarted-card-description {
    width: 100%;
    text-align: left;
    padding: 15px 15px 0px 15px;
    font-size: 0.8em;
}

.ant-card-body {
    height: 100%;
}

.getstarted-card-price-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 3rem;
}

.getstarted-card-price {
    text-align: left;
    padding: 10px 15px 0px 15px;
    font-size: 0.85em;
    font-weight: 700;
    margin-top: 15px;
}

.getstarted-card-price-details {
    text-align: left;
    font-size: 0.6em;
    padding: 0px 15px;
    margin-bottom: 10px;
    line-height: 7px;
    opacity: 0.7;
}

.getstarted-card-details li {
    text-align: left;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
}

.getstarted-card-button {
    height: 3rem;
    margin-top: auto;
    margin-left: 15px;
    margin-bottom: 15px;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--primary-color);
    font-size: 0.8em;
    font-weight: 700;
    border: none;
    border-radius: 5px;
}

.getstarted-card-button:hover {
    background-color: var(--accent-color) !important;
}

.ant-card-body {
    display: flex;
    flex-direction: column;
}

.getstarted-card-content {
    flex-grow: 1;
    display: inherit;
    flex-direction: inherit;
}

.contact-us-styling p {
    color: var(--text-color);
}

.contact-us-styling h2 {
    color: var(--primary-color);
}

.features-table {
    margin-top: 25px;
}

@media screen and (max-width: 768px) {
    .contact-us-styling {
        padding: 0 20px;
    }

    .custom-card-root-container {
        padding: 0px 10px;
        margin-top: 0px;
        height: 90%;
    }

    .package-card-container {
        padding: 20px;
    }

    .getstarted-card {
        padding: 10px;
        font-size: 1.3em;
    }

    .getstarted-card-title {
        font-size: 1.4em;
    }

    .getstarted-card-description {
        padding: 10px;
        font-size: 1em;
    }

    .getstarted-card-price {
        padding: 10px;
        font-size: 1em;
    }

    .getstarted-card-price-details {
        padding: 0px 10px;
        font-size: 0.69em;
    }

    .getstarted-card-details li {
        font-size: 0.95em;
    }

    .getstarted-card-button {
        font-size: 0.8em;
    }
    
}