
.hero-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    width: 100%;
}

.black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* gradient */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)); /* Adjust the gradient as needed */
}

.title-container-root {
    display: flex;
    max-width: 1140px;
    width: 85%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.title-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.hero-title {
    display: inline-block;
    font-size: 48px;
    letter-spacing: 15px;
    padding: 15px 0;
    z-index: 5;
}  

.hero-subtitle {
    display: inline-block;
    font-size: 24px;
    letter-spacing: 3px;
}

.hero-button {
    display: inline-block;
    margin-top: 25px;
    height: 3rem;
    background-color: var(--accent-color);

    font-size: 0.6em;
    font-weight: 700;
    border: none;
    border-radius: 5px;
}

.hero-button:hover {
    background-color: rgba(255, 255, 255, 0.9) !important;
    color: var(--primary-color) !important;
}

/* Animation */
.hero-title span {
    display: inline-block;
    animation: grow 1.5s forwards ease-in-out 1.5s;
    opacity: 0;
  }
  
  @keyframes grow {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    100% {
      transform: scale(1.5); /* Adjust the scale factor as needed */
      opacity: 1;
    }
  }

/* Mobile */
@media screen and (max-width: 768px) {
    .title-container-root {
        justify-content: center;
    }
    .title-container {
        align-items: center;
        justify-content: center;
    }
    .hero-title {
        font-size: 44px;
        letter-spacing: 10px;
    }
    .hero-button {
        margin-top: 15px;
        width: 50%;
    }
}