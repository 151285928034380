.App {
  text-align: center;
  overflow: hidden;
}

.Background-image {
  opacity: 0.5;
  position: fixed; /* Fixed positioning for the background image */
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  z-index: -1;
  overflow: hidden;
}

.App-content {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
