.services-root-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100vw - 40px;
    background-color: var(--background-color);
    height: 100%;
}

.services-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    max-width: 1140px;
}

/* About.tsx */
.about-root-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px;
    background: var(--background-color);
    max-height: 50vh;
}

.about-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    max-width: 1140px;
}

.about-us-card {
    min-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    font-size: 1.3rem;
    height: 100%;
    background: var(--background-color);
    border: none;
}

.about-us-card-title {
    font-size: 2.0rem !important;
    margin-bottom: 20px;
}

.about-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    border-radius: 10px;

}

.about-image {
    max-width: 150%; /* Ensure the image doesn't exceed its container's width */
    height: auto; /* Maintain the image's aspect ratio */
}


/* Contact.tsx */
.contact-root-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px;
    background: var(--background-color);
    height: 25vh;
}

.contact-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;
    max-width: 1140px;
}

.contact-row {
    width: 100%;
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding: 20px;
    font-size: 1.2rem;
    height: 100%;
    border: none;
    width: 100%;
}

.contact-info-container-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.contact-title {
    font-size: 0.9rem;
    margin-bottom: 15px;
    font-weight: normal;
    color: var(--text-color);
}

.contact-info-subtitle {
    font-size: 1.0rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: var(--text-color);
}

.contact-info {
    margin-top: 0px;
    margin-bottom: 6px;
    font-size: 0.9rem;
    font-weight: thin;
    color: var(--text-color);
}

.follow-info {
    margin-bottom: 25px;
    font-size: 1.8rem;
    font-weight: bold;
    cursor: pointer;
    color: var(--primary-color);
    transition: color 0.3s;
}

.follow-info:hover {
    color: var(--accent-color);
}

.follow-info:active {
    color: var(--primary-color);
}

@media screen and (max-width: 768px) {

    .services-root-container {
        padding: 0px;
        padding-top: 20px;
    }

    .about-root-container {
        padding: 20px;
        max-height: unset;
    }

    .about-container {
        flex-direction: column;
    }

    .about-us-card-title {
        font-size: 1.5rem !important;
    }

    .about-image-container {
        height: 80%;
        margin-top: 20px;
    }

    .contact-container {
        flex-direction: column;
    }

    .contact-root-container {
        padding: 30px;
        height: 45vh;
    }

    .contact-info-container {
        padding: 10px;
        width: 100%;
        justify-content: flex-start;
    }

    .contact-info-subtitle {
        width: 100%;
        font-size: 1.1rem;
        margin-bottom: 10px;
    }
    
}
