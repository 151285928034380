.items-root-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;
}

.items-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    max-width: 1500px;
}

.demo-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
    border-radius: 5px;
    width: calc(100% / 3 - 40px);
    cursor: pointer;
}

.demo-item-img {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    border-color: white;
    border-style: solid;
    border-width: 10px;
}

.demo-item-name-container {
    padding: 10px;
    width: 85%;
    text-align: center;
}

.items-top-ribbon {
    height: 138px;
    background-size: auto 138px;
    background-position: center bottom;
}

.demo-item-name {
    font-size: 2.25em;
    font-family: 'gringoregular';
    color: #222323;
    font-weight: 400;
    line-height: 1.4;
    -moz-osx-font-smoothing: grayscale;
}

.items-category-title {
    font-size: 4.06em;
    font-style: italic;
    color: #fe3b1f;
    margin-bottom: 0;
    letter-spacing: 1px;
    font-family: 'luchadoroblique';
    font-weight: 600;
    text-rendering: optimizeLegibility;
}


@media screen and (max-width: 768px) {
    .items-root-container {
        padding: 0px;
    }
    .items-container {
        flex-direction: column;
        align-items: center;
    }

    .demo-item-container {
        width: 100%;
    }

    .demo-item-name {
        font-size: 1.7em;
    }
}