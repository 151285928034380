.demo-header {
    background: none;
    padding: 1px;
    max-height: 7vmin;
}

.demo-header-title {
    margin: 0;
    font-size: 1.5em;
    font-family: 'luchadoroblique';
    color: #fe3b1f;
}

.demo-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-size: calc(10px + 2vmin);
    color: rgb(0, 0, 0);
    height: 100%;
    max-height: 7vmin;
    padding: 0 0 0 10px;
    width: 100%;
}

.demo-header-container-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.demo-header-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.demo-header-container-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.demo-header-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
    height: 7.5vmin;
    transition: background-color 0.3s;
}

.demo-header-button:hover {
    background-color: #00269A;
}

.demo-app-logo {
    height: 7vmin;
    pointer-events: none;
    opacity: 1;
    border-radius: 5%; 
    margin-right: 10px;
    cursor: pointer;
}

.demo-header-container-left {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .demo-header {
        background: none;
        padding: 1px;
        max-height: 12vmin;
        height: 12vmin;
    }

    .demo-header-container {
        padding: 0 0 0 5px;
        max-height: 12vmin;
    }

    .demo-header-button {
        padding: 0px 15px;
        font-size: 16px;
        height: 14vmin;
    }

    .demo-header-title {
        font-size: 1.2em;
    }

    .demo-app-logo {
        height: 12vmin;
    }
}