.page-container-root {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.page-container-root h1 {
    color: var(--primary-color);
}

.page-container p {
    color: var(--text-color);
    opacity: 0.8;
}

.page-container li {
    color: var(--text-color);
    opacity: 0.8;
}

.page-container {
    max-width: 1140px;
    justify-content: center;
    width: 97%;
}

.page-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 50px;
    margin-top: 10vh;
    text-align: center;
    max-width: 90%;
    white-space: wrap;
    text-wrap: pretty;
    color: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 768px) {

    .page-container {
        margin: 20px 0;
    }

    .page-title {
        margin-top: 14vh;
        margin-bottom: 0px;
        font-size: 1.8em;
    }
    
}
