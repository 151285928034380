.Header-container {
    position: absolute;
    margin-top: 10px;
    top: 0;
    left: 0;
    padding-left: 35px;
    padding-right: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: calc(100% - 70px);
    z-index: 1000;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Header-title {
    font-size: 0.5em;
    margin-top: 0;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
}

.Header-subtitle {
    font-size: 0.5em;
    background-color: #6f6f6f8f;
    padding: 10px;
    margin-top: 0;
    max-width: 50%;
}

.header-container-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.header-container-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.header-menu-item {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 0.5em;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.header-menu-item:hover {
    color: #f0f0f0;
}

.App-logo {
    height: 7vmin;
    pointer-events: none;
    opacity: 1;
    border-radius: 5%; 
    margin-right: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .header-container-mobile {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .Header-container {
        padding-left: 15px;
        padding-top: 5px;
        padding-right: 0px;
        width: calc(100% - 30px);
    }
    .header-container-right {
        display: none;
    }
    .Header-title {
        font-size: 0.75em;
    }
    .App-logo {
        height: 10vmin;
    }
    .header-container-mobile {
        display: flex;
        flex: 0.2;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
    }
    .header-container-mobile-menu {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1000;
    }
    .header-container-menu-icon {
       cursor: pointer;
    }
    .slide-in {
        animation: slide-in 0.5s forwards;
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}
